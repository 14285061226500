.menu {
    display         : flex;
    flex-direction  : column;
    height          : 100%;
    width           : 0;
    position        : fixed;
    z-index         : 100;
    top             : 0;
    left            : 0;
    background-color: white;
    overflow-x      : hidden;
    transition      : 0.3s;
    border-radius   : 0 20px 20px 0;
}

.menuItems {
    padding: 0 7%;
}

.menuItems>ul {
    list-style-type     : none;
    padding-inline-start: 0;
}

.menu a {
    text-decoration: none;
    font-size      : 16px;
    color          : #3b465c;
    display        : block;
    transition     : 0.3s;
    margin         : 33px 0;
    cursor         : pointer;
}

.menu a:hover {
    color: #f1f1f1;
}

.login {
    display      : flex;
    align-items  : center;
    border-bottom: 1px solid rgba(59, 70, 92, 0.2);
    padding      : 6% 0;
    cursor       : pointer;
}

.login>p {
    margin-left: 10px;
    color      : #1779c0;
    font-weight: 500;
}