.root {
    display       : flex;
    flex-direction: column;
    margin-top    : 5%;
}

.whiteSpace {
    height          : 20px;
    background-color: white;
}

.container {
    display        : flex;
    flex-wrap      : wrap;    
    gap            : 1rem;
    justify-content: space-between;
    align-items    : center;
    padding        : 10px 18% 0 23%;    
}

.left {
    display: flex;
    cursor: pointer;
}

.right {
    display       : flex;
    flex-direction: column;
}

.right>p,
.right>div>span {
    font-size  : 12px;
    font-weight: 500;
    line-height: 1;
    text-align : left;
    color      : #38739d;
    margin     : 1px 0;
}

.iconosRoot {
    display        : flex;
    justify-content: flex-end;
    align-items    : center;
    margin-right: 45px;
}

.iconos {
    margin-left: 10px;
}

.separador {
    width: 1px;
    height: 40px;
    margin-left: 10px;
    margin-right: 10px;
    margin-top: 10px;
    background-color: #004F9F
}

.iconosRoot > svg {
    cursor: pointer;
}

.horizontalLine {
    display   : flex;
    height    : 8px;
    margin-top: 25px;
    margin-bottom: 15px;
}

.horizontalLine>div {
    flex-grow: 1;
}

.horizontalLine>div:nth-child(1) {
    background-color: #e1a113
}

.horizontalLine>div:nth-child(2) {
    background-color: #971b2f
}

.horizontalLine>div:nth-child(3) {
    background-color: #009fe3
}

.termsAndConditions {
  margin-bottom: 30px;
  color: #38739d;
  align-self: center;
  text-align: center;
  text-decoration: underline;
}

@media (max-width: 1366px) {
    .container {
        padding: 0 10%;
        padding-top: 10px;
    }
}

@media (max-width: 800px) {
    .container {
        padding: 0 5%;
        padding-top: 10px;
    }
}

@media (max-width: 500px) {
    .root {
        margin-top: 10%;
    }

    .container {
        flex-direction: column;
        height        : 220px;
        align-items   : flex-start;
    }

    .right>p {
        text-align: left;
    }

    .iconosRoot {
        justify-content: flex-start;
    }
}