.captchaContainer {
  width: 70%;
  display: flex;   
  justify-content: space-around;
  font-family: Arial, sans-serif;
  font-size: 14px;
  color: #000;
  background: #f9f9f9;
  border: 1px solid #ccc;
  border-radius: 5px;
  padding: 5px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);  
  height: 10dvh;
}

.captchaCheckbox {  
  display: flex;
  align-items: center;
  cursor: pointer;  
}

.captchaCheckbox p {
  font-size: 14px;
}
.captchaCheckbox input[type="checkbox"] {
  display: none;
}

.customCheckboxText {
  font-size: 1rem;
}

.customCheckbox {
  width: 20px;
  height: 20px;
  border: 2px solid #ccc;
  border-radius: 3px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 8px;
  background-color: #fff;
}

.customCheckbox:after {
  content: "✔";
  display: none;
  font-size: 14px;
  color: #009688;
}

.captchaCheckbox input[type="checkbox"]:checked + .customCheckbox {
  border-color: #009688;
  background-color: #e0f7fa;
}

.captchaCheckbox input[type="checkbox"]:checked + .customCheckbox:after {
  display: block;
}

.captchaBranding {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-top: 10px;
}

.captchaPrivacy {
  font-size: 12px;
  color: #777;
}

.imgLogo {
  width: 20%;
}
