.container {
    display        : flex;
    justify-content: space-between;
    padding        : 180px 23%;
    padding-bottom : 0;
}

.leftItem {
    display       : flex;
    flex-direction: column;
}

.title {
    font-size     : 48px;
    font-weight   : bold;
    font-stretch  : normal;
    font-style    : normal;
    line-height   : 1.21;
    letter-spacing: normal;
    text-align    : justify;
    color         : #3b465c;
    margin        : 0 0 15px 0;
}

.description {
    width         : 434px;
    font-size     : 16px;
    font-weight   : normal;
    font-stretch  : normal;
    font-style    : normal;
    line-height   : 1.13;
    letter-spacing: 0.01px;
    color         : #3B465C99;
}

.button {
    width           : 220px;
    height          : 51px;
    border-radius   : 26px;
    border          : 2px solid #1779C0;
    background-color: #1779C0;
    font-weight     : 500;
    font-stretch    : normal;
    font-style      : normal;
    line-height     : 1.29;
    letter-spacing  : 0.02px;
    font-size       : 16px;
    color           : white;
    cursor          : pointer;
}


@media (max-width: 1920px) {
    .container {
        padding       : 180px 21%;
        padding-bottom: 0;
    }
}

@media (max-width: 1600px) {
    .container {
        padding       : 180px 15%;
        padding-bottom: 0;
    }
}

@media (max-width: 1440px) {
    .container {
        padding       : 180px 12%;
        padding-bottom: 0;
    }
}

@media (max-width: 1366px) {
    .container {
        padding       : 180px 16%;
        padding-bottom: 0;
    }
}

@media (max-width: 1024px) {
    .container {
        padding       : 180px 9%;
        padding-bottom: 0;
    }
}

@media (max-width: 800px) {
    .container {
        flex-direction: column;
    }

    .title {
        font-size: 32px;
    }

    .button {
        margin-bottom: 36px;
    }
}

@media (max-width: 640px) {
    .container {
        padding: 85px 9% 0;
    }
}

@media (max-width: 480px) {
    .description {
        width: auto
    }
}

@media (max-width: 414px) {

}

@media (max-width: 375px) {
    .container {
        padding: 85px 7% 0;
    }
}

@media (max-width: 360px) {
  
}

@media (max-width: 320px) {
   
}