.container {
    display    : flex;
    align-items: center;
    height     : 80px;
    color      : white;
}

.containerMobile {
    display: none;
    height : 80px;
    color  : white;
}

.fixed {
    position        : fixed;
    top             : 0;
    width           : 100%;
    overflow        : hidden;
    background-color: transparent;
    transition      : all 0.15s linear;
    z-index         : 10;
}

.shadow {
    box-shadow: 5px 2px 4px rgba(0, 0, 0, .5);
    background-color   : #009EE2;
}

.rightLinks {
    display        : flex;
    justify-content: flex-end;
    align-items    : baseline;
    flex-grow      : 1;
}

.link {
    margin-left    : 20px;
    height         : 24px;
    font-size      : 16px;
    font-weight    : bold;
    font-stretch   : normal;
    font-style     : normal;
    line-height    : 0.9;
    letter-spacing : 0.01px;
    text-align     : right;
    color          : #ffffff;
    cursor         : pointer;
    text-decoration: none;
}

.button {
    width           : 190px;
    height          : 52px;
    border-radius   : 26px;
    border          : solid 1.5px white;
    background-color: white;
    text-align      : center;
    font-family     : 'Maven Pro';
    color           : #4AA9D8;
    margin-left     : 20px;
    transition: background-color 0.3s ease, transform 0.2s ease;
   
}

.button:active {
    background-color: #4AA9D8;
    color: white;  
    transform: scale(0.95);
}

.buttonDisabled {
    width           : 190px;
    height          : 52px;
    border-radius   : 26px;
    border          : solid 1.5px #ccc;
    background-color: #ccc; 
    text-align      : center;
    font-family     : 'Maven Pro';
    color           : #2e2e2e;
    margin-left     : 20px;   
}

@media (max-width: 1920px) {
    .container {
        padding: 15px 20% 0;
    }
}

@media (max-width: 1600px) {
    .container {
        padding: 15px 15% 0;
    }
}

@media (max-width: 1440px) {
    .container {
        padding: 15px 11% 0;
    }
}

@media (max-width: 1366px) {
    .container {
        padding: 15px 7% 0;
    }
}

@media (max-width: 1024px) {
}

@media (max-width: 800px) {
    .container {
        padding: 15px 5% 0;
    }

    .link {
        font-size  : 14px;
        margin-left: 20px;
    }

    .button {
        width    : 150px;
        font-size: 14px;
    }

    .buttonDisabled {
        width    : 150px;
        font-size: 14px;
    }
}

@media (max-width: 640px) {
    .container {
        display: none;
    }

    .containerMobile {
        display    : flex;
        align-items: center;
        padding    : 0 5%;
    }

    .button {
        width : 120px;
        height: 42px;
    }
    
    .buttonDisabled {
        width : 120px;
        height: 42px;
    }

    .buttonDisabled {
        width : 120px;
        height: 42px;
    }

    .icon {
        font-size   : 30px;
        cursor      : pointer;
        color       : 'white';
        margin-right: 3%;
    }
}

@media (max-width: 375px) {
    .containerMobile {
        padding: 0 4%;
    }

}