.form {  
  max-width: 100dvw;
  max-height: 100dvh;
  width: 100dvh;      
  height: 100dvh;      
  display: flex;  
  justify-content: center;    
}
.container {    
    height: auto;    
    width: 100%;
    background-color: #ffffff;
    border-radius: 8px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);  
    text-align: center;
    display: flex; 
    flex-direction: column; 
    align-items: center; 
    /* justify-content: center;  */
    padding: 2rem; 
    font-family: Arial, sans-serif; 
    overflow-x: hidden;
  }
  .title {   
    font-size: 20px; 
    margin-bottom: 10px; 
    text-align: center; 
  }
  .subtitle {
    font-size: 16px; 
    margin-bottom: 20px; 
    text-align: center; 
  }
  .input {
    max-width: 80dvw; 
    padding: 1rem; 
    font-size: 16px; 
    margin-bottom: 20px; 
    border: 1px solid #ccc; 
    border-radius: 5px; 
  }

  .buttonEnabled {
    background-color: #289AD6; 
    color: #fff; 
    padding: 10px 20px; 
    font-size: 16px; 
    border: none; 
    border-radius: 5px; 
    cursor: pointer; 
  }

  .buttonDisabled {
    background-color: #ccc; 
    color: #666; 
    padding: 10px 20px; 
    font-size: 16px; 
    border: none; 
    border-radius: 5px; 
    cursor: not-allowed; 
  }

  .cancelButton {
    margin-top: 10px; 
    background-color: transparent; 
    color: #289AD6; 
    border: none; 
    cursor: pointer; 
    font-size: 14px; 
  }
  
  .containerImg{
    width: 100%;
    height: 20dvh;
    margin: 50px 0;
  }

  .containerImg img {
    max-width: 100%;
    height: auto;
    display: block;
    margin: 0 auto;
  }

  /**/
  .inputContainer {
    width: 100%;
    position: relative;
    margin-top: 20px;
  }
  
  input {
    padding: 10px 5px;
    font-size: 16px;
    border: 1px solid #ccc;
    border-radius: 5px;
    outline: none;
    width: 80%;
  }
  
  input:focus {
    border-color: #289AD6; 
    box-shadow: 0px 0px 5px rgba(0, 123, 255, 0.5);
  }

  input[data-error="true"] {
    border-color: #9c1733;
    box-shadow: 0px 0px 5px rgba(214, 40, 40, 0.5);
}
  
  .labelCuil {
    position: absolute;
    bottom: 50%;
    left: 7%;      
    width: 80%;
    background-color: white;
    color: #aaa;
    font-size: 16px;
    pointer-events: none;
    transition: all 0.2s ease; 
    white-space: nowrap;    
    padding: 5px;
  }
  
  input:focus + label,
  input:not(:placeholder-shown) + label {
    top: -10px;
    left: 2rem;     
    width: 2rem;
    height: 1rem;
    transform: translateY(-50%);
    transform: translateX(-40%);
    color: #289AD6; 
    font-size: 12px;   
  }

  .errorText{
    font-size: 8; 
    color:  #9c1733;  
    padding: 10;
    margin-top: 0;
    text-align: center; 
  }

  @media (min-width: 769px) {
    .form {
        width: 100dvw;
    }
    input:focus + label,
    input:not(:placeholder-shown) + label {
      transform: translateX(40%);
    }
  }
  
  @media (min-width: 1000px) {
    .form {
      width: 40dvw;
    }
    
  }