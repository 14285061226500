  .container {    
    margin: 0;
    font-family: Arial, sans-serif;
    display: flex;
    justify-content: center;
    align-items: center;
    max-height: 100dvh;
    max-width: 100dvw;   
    height: 100dvh;
    background-color: #f7f9fc;    
    overflow: hidden;    
  }

  .containerVerificar {    
    height: auto;   
    width: 100dvw;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .card {    
    display: flex;
    flex-direction: column;
    justify-content: center;
    background: white;    
    border-radius: 10px;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
    text-align: center;    
    height: auto;   
    width: 100%;
    padding: 20px 30px;    
  }
  
  .illustration img {
    max-width: 100%;
    height: auto;
    margin-bottom: 3rem;
  }
  
  h2 {
    font-size: 1.1rem;
    line-height: 1.5em;
    color: #333;
    margin-bottom: 3rem;
  }
  
  input[type="number"]::-webkit-inner-spin-button, 
  input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
  }

  input[type="number"] {
  -moz-appearance: textfield;
  }

  .codeInput {
    width: 100%;
    display: flex;
    justify-content: space-around;
    gap: 10px;
    margin-bottom: 1rem;
  }  

  .codeInput input {    
    width: 12%;
    font-size: 1rem;
    text-align: center;
    border: 1px solid #ccc;
    border-radius: 5px;
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.1);
    outline: none;
    transition: border 0.2s ease-in-out;
  }

  .errorText{
    font-size: 8; 
    color:  #9c1733;  
    margin: -10 ;
    margin-top: 0;
    text-align: center; 
  }
   
  .codeInput input:focus {
    border: 1px solid #289AD6;
    box-shadow: 0px 0px 5px rgba(0, 123, 255, 0.5);
  }

  .codeDisplay {
    width: 100%;
    display: flex;
    justify-content: space-around;
    gap: 10px;
    margin-bottom: 1rem;   
  }
  
  .codeDisplay input {    
    width: 12%;
    font-size: 1rem;
    text-align: center;
    border: 1px solid #ccc;
    border-radius: 5px;
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.1);
    outline: none;
    transition: border 0.2s ease-in-out;
  }
  
  .codeDisplay span {
    width: 12%;
    font-size: 1rem;
    text-align: center;
    border: 1px solid #ccc;
    background-color: #f0f0f0;
    border-radius: 5px;
    padding: 10px 12px;
  }
  
  .disabledButton {
    background: #e0e0e0;
    border: none;
    color: #666;
    padding: 10px 20px;
    border-radius: 5px;
    margin-top: 20px;
    cursor: not-allowed;
  }
  
  .confirmButton {
    background: #289AD6;
    color: white;
    border: none;
    padding: 10px 20px;
    border-radius: 5px;
    margin-top: 20px;
    cursor: pointer;
  }
  
  .confirmButton:hover {
    background: #0D4D88;
  }  

  .cancelButton:hover{
    cursor: pointer;
  }

  .cancelButton{
    background: transparent;
    color: #289AD6;
    padding: 10px 20px;
    border: none;
  }  
  
  .footer {
    height: 15%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-top: 15px;
  }
  
  .footer p {
    margin-bottom: 0.5em;
  }

  .footer button {
    display: block;
    color: #289AD6;
    text-decoration: none;
    margin-top: 10px;
    font-size: 14px;    
    background-color: transparent;
    border: none;
    cursor: pointer;
  }

  .footer button:disabled {
    cursor: not-allowed;
  }
  
  @media (min-width: 768px) {
    .container {
        width: 100dvw;
    }
  }
  @media (min-width: 769px) {
    .container {
      width: 50dvw;
    }
  }
  @media (min-width: 900px) {
    .container {
      width: 40dvw;
    }
  }