.section {
    padding-top: 90px;
    margin     : 90px 0;
}

.container {
    height          : 656px;
    display         : flex;
    justify-content : space-between;
    align-items     : center;
    background-color: #6AC4E7;
    color           : white;
    padding         : 0 23%;
}

.handContainer {
    width           : 445px;
    height          : 456px;
    border-radius   : 228px;
    background-color: #1779C0;
    position        : relative;
}

.imageRoot {
    position: absolute;
    top     : 53px;
    right   : 102px;
}

.img {}

.rightItem {
    height         : 370px;
    width          : 445px;
    display        : flex;
    flex-direction : column;
    justify-content: flex-end;
}

.title {
    font-size     : 48px;
    font-weight   : normal;
    font-stretch  : normal;
    font-style    : normal;
    line-height   : 1.21;
    letter-spacing: normal;
    margin        : 0 0 15px 0;
}

.description {
    font-size     : 16px;
    font-weight   : normal;
    font-stretch  : normal;
    font-style    : normal;
    line-height   : 1.13;
    letter-spacing: 0.01px;
}

.button {
    width           : 255px;
    height          : 52px;
    border-radius   : 26px;
    border          : 2px solid #1779C0;
    background-color: #1779C0;
    font-weight     : 500;
    font-stretch    : normal;
    font-style      : normal;
    line-height     : 1.29;
    letter-spacing  : 0.02px;
    font-size       : 16px;
    color           : white;
    cursor          : pointer;
    margin          : 20px 0;
}

.enlacesRoot {
    display        : flex;
    justify-content: flex-start;
    flex-wrap      : wrap;
}

.enlacesRoot>a {
    font-size  : 16px;
    font-weight: 500;
    line-height: 0.82;
    text-align : left;
    color      : white;
    padding    : 10px;
    margin-left: 45px;
}

@media (max-width: 1920px) {
    .container {
        padding: 0 21%;
    }
}

@media (max-width: 1600px) {
    .container {
        padding: 0 15%;
    }
}

@media (max-width: 1440px) {
    .container {
        padding: 0 12%;
    }
}

@media (max-width: 1366px) {
    .container {
        height : 500px;
        padding: 0 16%;
    }

    .handContainer {
        width : 359px;
        height: 369px;
    }

    .imageRoot {
        top  : 34px;
        right: 90px;
    }

    .img {
        width : 300px;
        height: 400px;
    }
}

@media (max-width: 1024px) {
    .container {
        padding: 0 7%;
    }

    .imageRoot {
        top  : 37px;
        right: 85px;
    }

    .img {
        width : 306px;
        height: 397px;
    }
}

@media (max-width: 800px) {
    .container {
        flex-direction : column-reverse;
        justify-content: space-between;
        height         : 742px;
        padding        : 0 9%;
        box-sizing     : border-box;
    }

    .handContainer {
        width        : 235px;
        height       : 240px;
        margin-bottom: 52px;
    }

    .imageRoot {
        top  : 27px;
        right: 59px;
    }

    .img {
        width : 195px;
        height: 265px;
    }

    .rightItem {
        width          : auto;
        justify-content: flex-start;
    }

    .title {
        font-size: 32px;
        margin   : 50px 0 15px 0;
    }

    .button {
        margin: 40px auto 20px;
    }

    .enlacesRoot {
        margin: 0 auto;
    }

    .enlacesRoot {
        font-size: 16px;
    }

    .enlacesRoot>a {
        font-size: 14px;
        margin   : 0;
    }
}

@media (max-width: 640px) {
    .section {
        padding-top: 85px;
        margin: 0;
    }
}
@media (max-width: 480px) {
    .button {
        margin: 25px auto 20px;
    }
}

@media (max-width: 375px) {
    .container {
        padding        : 0 7%;
    }
    .button{
        margin: 5px auto
    }
}


@media (max-width: 320px) {
  
}