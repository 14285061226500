.header {
  height: auto;
  background-color: transparent;
}

.imagenPrincipal {
  position: absolute;
  right: 0;
  z-index: -1;
  max-height: 860px;
}

.headerContainer {
    width         : 70%;
    height        : auto;
    min-height    : 625px;
    display       : flex;
    flex-direction: column;
    padding       : 185px 95px 0 130px;
    background    : transparent linear-gradient(125deg, #0D4D88 0%, #289AD6 100%) 0% 0% no-repeat padding-box;
    border-radius : 0px 0px 139px 0px;
    box-sizing    : border-box;
    margin-bottom : 235px;
}

.headerContainer > h1 {
  font-size: 64px;
  letter-spacing: 0.03px;
  line-height: 1;
  text-align: left;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  color: white;
  max-width: 540px;
  margin: 12px 0;
}

.headerContainer > p {
  font-size: 24px;
  font-weight: 300;
  text-align: left;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  color: white;
  max-width: 540px;
  margin: 12px 0;
}

.vinculosRoot {
  display: flex;
  justify-content: flex-start;
  margin: 26px 0;
  flex-wrap: wrap;
}

.headerContainer > a {
  font-size: 16px;
  text-align: left;
  font-weight: bold;
  color: white;
  margin-bottom: 15px;
  text-decoration: underline;
  cursor: pointer;
}

.vinculo {
  min-width: fit-content;
  padding: 15px 20px;
  height: 52px;
  border-radius: 26px;
  border: solid 2px white;
  background-color: transparent;
  color: #ffffff;
  text-align: center;
  font-family: "Maven Pro";
  margin: 7px 5px;
  cursor: pointer;
}

.vinculo:hover {
  background-color: #ffffff;
  color: #009ee2;
}

@media (max-width: 1920px) {
  .headerContainer {
    width: 61.5%;
    padding: 185px 95px 0 22%;
  }
}

@media (max-width: 1600px) {
  .headerContainer {
    width: 65%;
    padding: 185px 95px 0 17%;
  }
}

@media (max-width: 1440px) {
  .headerContainer {
    width: 55.5%;
    padding: 185px 95px 0 13%;
  }
}

@media (max-width: 1366px) {
  .headerContainer {
    width: 53.5%;
    padding: 185px 95px 0 9%;
  }
}

@media (max-width: 1200px) {
  .headerContainer {
    width: 60.5%;
  }
}

@media (max-width: 1024px) {
  .headerContainer {
    width: 53.5%;
    margin-bottom: 135px;
  }

  .headerContainer > a {
    margin: 0 auto 15px;
  }

  .vinculosRoot {
    justify-content: flex-start;
    flex-wrap: wrap;
  }
}

@media (max-width: 800px) {
    .headerContainer {
        width        : 61%;
        padding      : 185px 36px 0 9%;
        margin-bottom: 75px;
        min-height: 55vh;
    }

  .headerContainer > h1 {
    font-size: 34px;
  }

  .headerContainer > p {
    font-size: 16px;
  }

  .imagenPrincipal {
    max-height: 700px;
  }

  .vinculo {
    font-size: 12px;
  }
}

@media (max-width: 640px) {
  .headerContainer {
    width        : 100%;
    margin-bottom: 0;
    /* min-height   : 560px; */
  }
  .imagenPrincipal {
    display: none;
  }
}

@media (max-width: 480px) {
  .headerContainer {
    min-height: 400px;

    padding: 155px 36px 0 9%;
    border-radius: 0px 0px 60px 0px;
  }

  .headerContainer > a {
    font-size: 14px;
  }
}

@media (max-width: 414px) {
}

@media (max-width: 375px) {
    .headerContainer {
        /* min-height: 600px; */
        padding   : 155px 36px 0 7%;
    }
}

@media (max-width: 360px) {
}

@media (max-width: 320px) {
    .headerContainer {
        /* min-height: 685px; */
        padding   : 135px 36px 0 7%;
    }
}
