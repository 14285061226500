.root {
  display: grid;  
  grid-template: auto 1fr auto;  
  min-height: 100dvh;  
  max-width: 100vw;
}

.headerContainer {
  width         : 100%;
  height        : auto;
  display       : flex;
  flex-direction: column;
  padding: 50px 130px 40px 130px;
  background    : transparent linear-gradient(125deg, #0D4D88 0%, #289AD6 100%) 0% 0% no-repeat padding-box;
  border-radius : 0px 0px 139px 0px;
  box-sizing    : border-box;
  margin-bottom : 25px;
}

.header {
  display: flex;
  color: white;
  height: auto;
  justify-content: center;
}

.logo {
  width: 125px;
  margin-right: 20px;
  object-fit: contain;
}

.container{
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 130px;
  max-width: 100dvw;

  p {
    font-size: 18px;
    line-height: 1.78;
    text-align: left;
    color: #3b465c;
    margin: 0 0 15px 0;
    align-self: flex-start;
  }

  h3 {
    font-size: 18px;
    font-weight: bold;
    line-height: 1.21;
    text-align: left;
    color: #3b465c;
    align-self: flex-start;
  }
}

.title {
  font-size: 48px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  text-align: left;
  color: #fff;
}

.subtitle {
  font-size: 36px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.21;
  letter-spacing: normal;
  text-align: center;
  color: #3b465c;
  margin: 0 0 15px 0;
}

@media (max-width: 1000px) {
  .title {
      font-size: 36px;
  }
}

@media (max-width: 820px) {

  .headerContainer {
    padding: 30px 80px 20px 80px;
  }

  .logo {
    width: 100px;
    margin-right: 10px;
  }

  .title {
    font-size: 28px;
  }

  .subtitle {
    font-size: 26px;
  }

  .container {
    padding: 0 30px;
  }
}


@media (max-width: 600px) {

  .headerContainer {
    padding: 10px 50px 10px 50px;
    border-radius : 0px 0px 39px 0px;
  }

  .logo {
    width: 80px;
    margin-right: 5px;
  }

  .title {
    font-size: 24px;
  }

  .subtitle {
    font-size: 22px;
  }

  .container {
    padding: 0 20px;
  
    h3 {
      font-size: 18px;
    }
  }
}


@media (max-width: 465px) {

  .headerContainer {
    padding: 10px 20px 10px 20px;
    border-radius : 0px 0px 19px 0px;
  }

  .logo {
    width: 70px;
  }
}