  .container {
    max-width: 100dvw;
    max-height: 100dvh;
    height: 100dvh;    
    width: 90%;
    background-color: #ffffff;
    border-radius: 8px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    padding: 20px;
    text-align: center;
  }
  
  .container div {
    font-size: 1rem;
    font-weight: bold;
    color: #333333;
    
  }
  
  .container p {
    font-size: 0.9rem;
    color: #666666;
    margin-bottom: 20px;
    line-height: 1.4;
  }
  
  .container button {
    width: 100%;
    padding: 12px;
    font-size: 1rem;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    margin-bottom: 10px;
  }
  
  .container button:nth-child(1) {
    background-color: #289AD6 ;
    color: #ffffff;
  }
  
  .container button:nth-child(1):disabled {
    background-color: #cccccc;
    color: #333333;
  }

  .container button:nth-child(2) {    
    color: #289AD6;
    background-color: #ffffff;
  }
  
  .container button:hover {
    opacity: 0.9;
  }   
  
  .textContainer {
    height: 15%;
    margin: 20px 0;
  }

  .captchaContainer {
    display: flex;
    justify-content: center;  
    height: 15%;
    margin: 10px 0;
  }
  
  .captchaContainer div{
    font-size: 20px;
  }

  .captchaContainer p{
    font-size: 16px;
  }
  
  .captchaContainerImg{
    width: 100%;
    height: 20dvh;
    margin-top: 30px;
  }
  .captchaContainerImg img {
    max-width: 100%;
    height: auto;
    display: block;
    margin: 0 auto;
  }
  
  .footer {
    height: 15%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    font-size: 28px;
    color: #289AD6 ;
    
  }
  
  .footer p {
    margin-bottom: 0.5em;
  }
  
  .footer button {
    color: #289AD6 ;
    text-decoration: none;
  }
  
  .footer a:hover {
    text-decoration: underline;
  }

  @media (min-width: 302px) {
    .container {
      max-height: 100vh;
      height: 95dvh;
    }
  }
 
  @media (min-width: 769px) {
    .container {
      max-width: 500px;
      height: 100dvh;
      display: flex;
      flex-direction: column;
      gap: 1px
    
    }
  
    .container div {
      font-size: 1.5rem;
    }
  
    .container p {
      font-size: 1rem;
    }
  }

  @media (min-width: 1000px) {
    .container {
      height: 95dvh;
    }
  }