.backdrop {
    width           : 100%;
    height          : 100%;
    position        : fixed;
    z-index         : 50;
    left            : 0;
    top             : 0;
    opacity         : 0.4;
    background-color: #3b465c;
    display         : flex;
    justify-content : center;
    align-items     : center;
}