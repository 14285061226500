.modal{
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    min-height: 100vh;   
    align-content: center;
    justify-content: center;
    align-items: center;
    z-index: 1000;
    text-align: center; 
    display: none;
}

.isOpen{
    display: flex;
}

.modalContainer{
    background-color: #FFF;
    border-radius: 8px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    max-width: 100dvw;
    width: 100%;
    text-align: center;
    position: relative;
}

.modalButtonClose{
    padding: 5px 10px; 
    background-color:transparent; 
    border: none;
    font-size: 16px;
    font-weight: bold; 
    /* color: #1C9DD9;  */
    color: #fafafa; 
    border-radius: 25%; 
    position: absolute;
    top: 1rem; 
    right: 1rem;
}
