.card {
    display         : flex;
    flex-direction  : column;
    max-width       : 730px;
    width           : 100%;
    height          : 291px;
    border-radius   : 30px;
    background-color: white;
    box-sizing      : border-box;
}

.header {
    display         : flex;
    justify-content : center;
    align-items     : center;
    min-height      : 82px;
    background-color: #6AC4E7;
    border-radius   : 30px 30px 0 0;
}

.img {
    width       : 42px;
    height      : 42px;
    margin-right: 24px;
}

.titulo {
    font-size  : 24px;
    font-weight: 500;
    color      : white;
}

.contenido {
    line-height   : 1.19;
    letter-spacing: normal;
    text-align    : left;
    color         : #3b465c;
    padding       : 11px 60px;

}

@media (max-width: 850px) {
    .card {
        height: auto;
    }
}


@media (max-width: 600px) {
    .header {
        flex-direction: column;
        min-height    : 100px;
    }

    .img {
        margin: 10px 0;
    }

    .contenido {
        padding: 10px 30px;
    }
}
