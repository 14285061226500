body {
    font-family: Arial, sans-serif;
    background-color: #f0f0f0; 
    overflow-y: auto;
}

.loginContainer {
    display: flex;
    justify-content: center;
    align-items: center;    
    height: 100dvh;
    width: 100dvw;
}

.loginForm {
    margin-top: 100px;
    background-color: #fff; 
    padding: 20px;
    border-radius: 8px; 
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);     
    width: 80dvw;
}

.loginform h2 {
    text-align: center;
    margin-bottom: 20px; 
}

.loginInput {
    width: 100%; 
    padding: 13px; 
    margin-bottom: 15px; 
    border: 1px solid #ccc; 
    border-radius: 4px; 
    box-sizing: border-box; 
}

.loginButton {
    width: 100%; 
    padding: 1rem; 
    background-color: #004B81; 
    color: white; 
    border: none; 
    border-radius: 4px; 
    cursor: pointer; 
    margin-bottom: 10px;
    font-weight: bold;
    font-size: 18px;
}

.loginButton:focus {
    background-color: #1C9DD9; 
}

.loginInputPassword {    
    width: 80%;
    padding: 10px; 
    border: none; 
   
}

.passwordContainer {
    display: flex;
    justify-content: space-between;
    width: 100%;
    border: 1px solid #ccc; 
    border-radius: 4px;
    box-sizing: border-box;
    margin-bottom: 15px;
    width: 100%;
}

.eyeIcon{
    padding: 10px;
    z-index: 1000;
}

.cidiButton {   
    text-align: center;
    padding: 0.8rem; 
    background-color: white; 
    border: 2px solid #004B81; 
    color: #004B81;
    text-decoration: none; 
    border-radius: 4px; 
    cursor: pointer; 
    margin-top: 10px;
    margin-bottom: 10px;
}

.containerBlueText { 
    display: flex; 
    justify-content: center;
    align-content: center; 
    align-items: center; 
}

.blueText{
    font-size: clamp(0.9rem, 12px ,1.1rem); 
    color: #1C9DD9; 
    text-decoration: none;
}

.gridContainer{
    display: grid;
    grid-template-rows: repeat(3, auto);
    gap: 10px;
    grid-auto-rows: 33.3%;
}

.headerContainer{
    display: flex;
}

/* .headerContainer {    
    background-color: transparent;
    text-align: center;
    padding: 20px;
    height: 22dvh;  
    top: -100px  
}

.headerContainer h1 {
    font-size: 1.5rem;
    margin: 0;
} */

.otroItem{
    display: grid;
    height: 45dvh;

}
.imageContainer { 
    display: flex; 
    align-content: center; 
    justify-content: space-around;
    /* margin-top: -35dvh; 
    margin-left: -15dvw;
    margin-bottom: 10%;  */   
    position: absolute;    
    left: 0;
    top: 0;      
}

.logoCapital {    
   width: 15dvw;
   height: auto;
   margin-bottom: auto;
   position: relative;
   top: 5dvw; 
   left: 5dvw; 
   filter: drop-shadow(4px 6px 12px #004B8190)  ; 
   
}

.logoVedi {
    width: 35dvw;
    height: auto;    
    position: relative;
    top: 5dvh; 
    left: calc((100dvw - 35dvw)/4); 
    filter: drop-shadow(4px 6px 12px #004B8190);     
}

.wave {
    position: absolute;    
    left: 0;
    top: 0; 
    z-index: 0;
    width: 100%;
    height: 30dvh;
    background: url('../../../assets/images/wave.svg');
    background-size: cover;        
}

.errorText{
    font-size: 8; 
    color:  #9c1733;  
    /* box-shadow: 0 0 10px rgba(156, 23, 51);     */
    background-color: #9c173310;
    padding: 10;
    margin-top: 0;
    text-align: center; 
}

@media (max-height: 400px) {
    .loginContainer {                
        overflow-y: auto;
    }
    .headerContainer{
        display: none;
    }
}

@media (min-width: 500px) and (min-height: 500px){
    .loginContainer2 {                
        display: flex;
        justify-content: center;
        align-items: center;                    
    }

    .wave {
        display: flex;
        justify-content: center;
    }

    .logoVedi {
        width: 12dvw;
        left: calc((100dvw - 35dvw) / 2);
    }
    .logoCapital {
        width: 10dvw;
       
    }
    
}