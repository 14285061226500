.root {
    display        : flex;
    justify-content: center;
}

.spacing {
    margin: 0 2%;
}

.displayButtons {
    display: block;
}

.buttonsRoot {
    display: none;
}

.button {
    align-self      : center;
    border-color    : transparent;
    background-color: transparent;
}

.button:hover {
    cursor: pointer;
}

.button:hover>svg>g>g:first-child {
    stroke: #1779C0;
}

.button:hover>svg>g>g:last-child>* {
    stroke: #1779C0;
}

.fadeIn {
    animation: fadeIn ease 1.5s;
}


@keyframes fadeIn {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

@-moz-keyframes fadeIn {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

@-webkit-keyframes fadeIn {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

@-o-keyframes fadeIn {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

@-ms-keyframes fadeIn {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

/*************MEDIA QUERIES*************/

@media (max-width: 1366px) {
    .container {
        padding: 0 20% 0 21%;
    }

    .spacing {
        margin: 0 1%;
    }
}

@media (max-width: 600px) {
    .root {
        flex-direction: column;
    }

    .displayButtons {
        display: none;
    }

    .buttonsRoot {
        display        : flex;
        justify-content: flex-end;
        margin-top     : 25px;
        padding        : 0 10%;
        padding        : 0 3%;
    }
}