.root {
    padding: 90px 23%;
}

.titulo {
    font-size  : 48px;
    line-height: 1.21;
    font-weight: bold;
    text-align : center;
    color      : #3B465C;
    margin     : 0 0 25px;
}

.contenedorDescripcion {
    margin       : 0 auto;
    max-width    : 730px;
    margin-bottom: 40px;
}

.descripcion {
    width      : 537px;
    font-size  : 16px;
    line-height: 1.22;
    text-align : center;
    color      : #3b465c;
    margin     : 0 auto 45px auto;
}

.desktopView {
    display        : flex;
    justify-content: center;
    flex-wrap      : wrap;
}

.desktopViewItem {
    margin: 5px;
}


@media (max-width: 1920px) {
    .root {
        padding: 90px 21%;
    }
}

@media (max-width: 1600px) {
    .root {
        padding: 90px 15%;
    }
}

@media (max-width: 1440px) {
    .root {
        padding: 90px 12%;
    }
}

@media (max-width: 1366px) {
    .root {
        padding: 90px 9%;
    }
}

@media (max-width: 1024px) {
    .root {
        padding: 90px 2%;
    }
}

@media (max-width: 800px) {
    .root {
        padding: 90px 5%;
    }

    .titulo {
        font-size: 36px;
    }

    .descripcion {
        font-size: 12pt;
    }
}

@media (max-width: 480px) {
    .descripcion {
        width: auto;
    }
}